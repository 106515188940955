/* eslint-disable arrow-body-style */
// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React from 'react'
import { graphql } from 'gatsby'
import titleCase from 'voca/title_case'
import SeoPlugin from 'gatsby-plugin-wpgraphql-seo'
import Seo from '../../src/components/Seo'

import FlexibleContent from '../../src/pageBuilder/FlexibleContent'
import FeatureGrid from '../../src/pageBuilder/FeatureGrid'
import Faq from '../../src/pageBuilder/Faq'

function PageTemplate(pageProps) {
  let data
  const pageContext = pageProps
  data = pageProps.data
  const componentsArray = data?.location?.page?.pageBuilder?.components || []
  const components = componentsArray.map(component => ({
    name: component.__typename.split('_').pop(),
    data: component,
  }))
  return (
    <>
      {/* <Seo title={data?.location?.page?.seo?.title} description={data?.location?.page?.seo?.metaDesc} /> */}
      <SeoPlugin post={data} title={data?.location?.page?.seo?.title} description={data?.location?.page?.seo?.metaDesc} />
      {components.map((component, index) => {

        if (component.name === 'FlexibleContent') {
          return <FlexibleContent pageContext={pageContext} {...component.data} key={index} />
        }

        if (component.name === 'FeatureGrid') {
          return <FeatureGrid pageContext={pageContext} {...component.data} key={index} />
        }

        if (component.name === 'Faq') {
          return <Faq pageContext={pageContext} {...component.data} key={index} />
        }

        return (
          <div key={`${component.name}_${index}`} id={`${component.name}_${index}`} tw="border border-gray-100 my-4 p-4 rounded shadow-lg">
            <h3 tw="mb-4 font-bold text-xl">
              <span tw="text-red-800 mr-1">🤦‍♂️ Uh oh...</span> The component <span tw="text-red-800">{component.name}</span> was not found.
            </h3>
            <p>
              Create the necessary component files by running <code>npx crcf {component.name}</code> in your terminal.
            </p>
          </div>
        )
      })}
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query LocationQuery7328($name: String!, $supervisor: String!, $programLeader: String!) {
    location: location(name: {eq: $name}) {
      id
      name
      page {
        title
        seo {
          title
          metaDesc
        }
        pageBuilder {
  components {
    __typename
          
          ... on WpLocation_Pagebuilder_Components_BlogFeed {
            
    blogPosts {
        ... on WpPost {
          title
          uri
          id
          featuredImage {
            node {
              altText
              gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 600)
            }
          }
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
            }
          }
        }
      }
      description
      postsFromCategory
      showPostsTagged
      title
      button
      buttonText
      buttonLink {
        url
      }
    
          }      
          ... on WpLocation_Pagebuilder_Components_ContactForm {
            
        fieldGroupName
        
          }      
          ... on WpLocation_Pagebuilder_Components_Cta {
            
    buttonText
    buttonVariant
    link {
      url
    }
    size
    template
    text
    title
    image {
      altText
      gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1000)
    }
  
          }      
          ... on WpLocation_Pagebuilder_Components_Faq {
            
    title
    text
    questions {
      ... on WpFAQ {
        id
        title
        faq {
          answer
          question
        }
      }
    }
    image {
      altText
      gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 800)
    }
  
          }      
          ... on WpLocation_Pagebuilder_Components_FeatureGrid {
            
    iconBlocks {
      text
      link {
        url
      }
      icon {
        altText
        gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 200)
      }
    }
    description
  
          }      
          ... on WpLocation_Pagebuilder_Components_FeaturePost {
            
        author
        date
        excerpt
        excerptWordLength
        showCategory
        post {
        ... on WpPost {
                id
                title
                content
                uri
                date(formatString: "MMMM DD, YYYY")
                author {
                    node {
                        name
                    }
                }
                categories {
                    nodes {
                        name
                    }
                }
                featuredImage {
                    node {
                        altText
                        gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1000)
                    }
                }
            }
        }
    
          }      
          ... on WpLocation_Pagebuilder_Components_FlexibleContent {
            
  column1 {
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column1_Images {
      imagesRotate
      imagesVariant
      gallery {
        altText
        id
        gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1200)
      }
    }
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column1_LocationDetails {
      useDetails
    }
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column1_Text {
      text
    }
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column1_Buttons {
      buttonRow {
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column1_Buttons_ButtonRow_Button {
          buttonLabel
          icon
          iconPosition
          buttonSize
          buttonVariant
          buttonLink {
            url
          }
        }
      }
    }
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column1_TestimonialCard {
      testimonialPosts {
        ... on WpTestimonial {
          id
          testimonials {
            author
            testimonial
          }
        }
      }
    }
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column1_Cta {
      ctaButtonText
      ctaButtonVariant
      ctaTemplate
      ctaText
      ctaTitle
      ctaLink {
        url
      }
    }
  }
  column2 {
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column2_Images {
      imagesRotate
      imagesVariant
      gallery {
        altText
        id
        gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1200)
      }
    }
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column2_Text {
      text
    }
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column2_LocationDetails {
      useDetails
    }
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column2_Buttons {
      buttonRow {
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column2_Buttons_ButtonRow_Button {
          buttonLabel
          icon
          iconPosition
          buttonSize
          buttonVariant
          buttonLink {
            url
          }
        }
      }
    }
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column2_TestimonialCard {
      testimonialPosts {
        ... on WpTestimonial {
          id
          testimonials {
            author
            testimonial
          }
        }
      }
    }
... on WpLocation_Pagebuilder_Components_FlexibleContent_Column2_Cta {
      ctaButtonText
      ctaButtonVariant
      ctaTemplate
      ctaText
      ctaTitle
      ctaLink {
        url
      }
    }
  }
  
          }      
          ... on WpLocation_Pagebuilder_Components_LocationCta {
            
    title
    text
    template
    inputLabel
    imageUrl {
      altText
      gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1000)
    }
  
          }      
          ... on WpLocation_Pagebuilder_Components_ProgramSlider {
            
     tiles {
      description
      title
      link {
        url
      }
      image {
        gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 700)
        altText
      }
    }
    description
    title
  
          }      
          ... on WpLocation_Pagebuilder_Components_TabbedSlider {
            
    slides {
      ageGroup
      careType
      description
      title
      link {
        url
      }
      image {
        altText
        gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 700)
      }
    }
  
          }      
          ... on WpLocation_Pagebuilder_Components_Teams {
            
    title
    variant
    boardMembers {
      position
      member {
        ... on WpTeam {
          id
          title
          content
          uri
        }
      }
    }
    team {
      ... on WpTeam {
        id
        title
        content
        uri
        team {
          role
        }
        featuredImage {
          node {
            altText
            gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 600)
          }
        }
      }
    }
  
          }      
          ... on WpLocation_Pagebuilder_Components_Testimonials {
            
    title
    titleImage {
      altText
      gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 300)
    }
    testimonialList {
      ... on WpTestimonial {
        id
        testimonials {
          testimonial
          author
        }
        featuredImage {
          node {
            altText
            gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 200)
          }
        }
      }
    }
  
          }      
          ... on WpLocation_Pagebuilder_Components_VideoPlayer {
            
    url
    title
    description
    videoPosition
  
          }      
          ... on WpLocation_Pagebuilder_Components_Wysiwyg {
            
    content
  
          }
  }
}
      }
    }
    supervisor: allWpMediaItem(filter: {slug: {eq: $supervisor}}) {
      edges {
        node {
          title
          gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 200)
        }
      }
    }
    program: allWpMediaItem(filter: {slug: {eq: $programLeader}}) {
      edges {
        node {
          title
          gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 200)
        }
      }
    }
  }
`
